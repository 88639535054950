export function formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear(),
        hour = d.getHours(),
        minutes = d.getMinutes();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;
    if (hour < 10) 
        hour = '0' + hour;
    if (minutes < 10)
        minutes = '0' + minutes;      

    let formatedDate = [day, month, year].join('-');
    let time = [hour, minutes].join(':');

    return [formatedDate, time].join(' ');
}

export function stringArray(data,label) {
    var stringRes = ''
    if(data){
        data.forEach(item => {
            stringRes = stringRes.concat(",", item[label]);
        })
        return stringRes.substring(1)
    }
    return ''
}

export function csvmaker(data) {
    // Empty array for storing the values
    var csvRows = [];
    
    // Headers is basically a keys of an
    // object which is id, name, and
    // profession
    var headers = Object.keys(data[0]);
    // As for making csv format, headers
    // must be separated by comma and
    // pushing it into array
    csvRows.push(headers.join(';'));

    // Pushing Object values into array
    // with comma separation
    var values = ''
    data.forEach(item => {
        item['abstract'] = item['abstract'].replace(/;/g,' ').replace(/\n/g,' ')
        item['title'] = item['title'].replace(/;/g,' ').replace(/\n/g,' ')
        values = Object.values(item).join(';')
        csvRows.push(values)
    })

    // Returning the array joining with new line
    return csvRows.join('\n')
    }