import axios from 'axios'

var server_path = ''
if (process.env.VUE_APP_API_TYPE == 'local')
  server_path = process.env.VUE_APP_API_HTTP_S + '://' + process.env.VUE_APP_API_HOST + ':' + process.env.VUE_APP_API_PORT + '/';
else
  server_path = process.env.VUE_APP_API_HTTP_S + '://' + process.env.VUE_APP_API_HOST + '/';
  
////////////////// Generic Functions ////////////////////////
export function getData(url) {
  return axios
    .get(server_path + url)
    .then(res => res)
    .catch(error => {
      if (error.response) {
        return error.response
      } else {
        return error
      }
    })
}

export function postData(url, data) {
  return axios
    .post(server_path + url, data)
    .then(res => res)
    .catch(error => {
      if (error.response) {
        return error.response
      } else {
        return error
      }
    })
}

export function putData(url, id, data) {
  return axios
    .put(server_path + url + id + "/", data)
    .then(res => res)
    .catch(error => {
      if (error.response) {
        return error.response
      } else {
        return error
      }
    })
}

export function deleteData(url, id) {
  return axios
    .delete(server_path + url + id + "/")
    .then(res => res)
    .catch(error => {
      if (error.response) {
        return error.response
      } else {
        return error
      }
    })
}


////////////////// Specific Functions ////////////////////////
export function uploadFiles(url, data) {
  return axios
    .post(server_path + url, data, {
      headers: {
        "Content-Type": "multipart/form-data"
      },  
    })
    .then(res => res)
    .catch(error => {
      if (error.response) {
        return error.response
      } else {
        return error
      }
    })
}



export function getBathymetry(url) {
  return axios
    .get(url)
    .then((res) => res)
    .catch((error) => {
      if (error.response) {
        return error.response;
      } else {
        return error;
      }
    });
}

export function upload(form, file) {
  const url = server_path + 'upload/'
  const formData = new FormData()
  for (const key in form) {
    formData.append(key, form[key])
  }
  formData.append('file', file)
  return axios
    .post(url, formData, {
      headers: {
        "Content-Type": "multipart/form-data"
      },
    })
    .then(res => res)
    .catch(error => {
      this.$noty.error("Oops, something went wrong!", error)
    });
}