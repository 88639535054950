<template>
  <footer>
    2022 © Centro Tecnológico Naval y del Mar - &nbsp; <a href="#" style="margin-left: 6px;">Portal Web</a>
  </footer>
</template>

<script>
export default {
  name: 'vtFooter',
  data () {
    return {
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang= "scss" scoped>

</style>
