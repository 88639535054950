<template>
  <div id="app">
    <loader></loader>
    <vtHeader></vtHeader>
      <router-view></router-view>
    <vtFooter></vtFooter>
  </div>
</template>


<script>
import vtHeader from '@/components/header'
import vtFooter from '@/components/footer'
import loader from '@/components/loader'


export default {
  name: 'App',
  beforeMount(){
  },
  components: {
    vtHeader,
    vtFooter,
    loader,
    // confirmModal,
    // notificationModal
  },
   data() {
    return {

    }
  },
  computed: { 
  },
  mounted () {

  },
  methods: { 
  }
}
</script>

<style lang="scss">
#app {
  width:100%; 
  height:100%; 
  position:fixed; 
  left:0;
  top:0;
  overflow:hidden;
}

</style>
