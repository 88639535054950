<template>
  <div class="container-fluid p-0 home-bg">
    <div class="row vtmar-container">
      <div class="col-6 col-sm-5 col-md-4 col-lg-3 col-xl-3 p-0 h-100">
        <vtpanel></vtpanel>
      </div>
      <div class="col-6 col-sm-7 col-md-8 col-lg-9 col-xl-9 p-0 h-100">
        <papersList></papersList>
      </div>
    </div>
  </div>
</template>

<script>

import vtpanel from '@/components/panel'
import papersList from '@/components/papersList'

export default {
  name: 'Vtmar',
  components: {
    vtpanel,
    papersList
  },
  computed: {
  },
  data() {
    return {
  
    }
  },
  created () {
   
  },
  mounted () {
  },
  watch: {
  },
  methods: {
 
  }
}
</script>
<style scoped>
</style>