<template>
    <div class="papers-container">
        <div v-if="papers_raw != ''" class="papers-list">
            <div  class="row papers-list-header">
                <div class="form-check ml-4 col-auto">
                    <!-- <button class="btn mt-2"><img :src="require('@/assets/icons/excel-icon.png')" alt="excel-download" style="height: 38px; width: auto;" /> </button> -->
                    <input class="form-check-input  mt-4" type="checkbox"  v-model="selectAll" @click="select" >
    
                </div>
                <div class="col-auto pt-3 pl-0 mr-0 navbar-item-list ">
                    <p>Resultados: <b> {{filteredPapers_v2.length }}</b></p>
                </div>
                <div class="col-md-4 col-md-5 col-lg-6 col-xl-7 mt-1 pr-5">
                    <!-- <button class="btn navbar-item-list " @click="downloadPapers"> Descargar papers seleccionados: <img :src="require('@/assets/icons/excel-icon.png')" alt="excel-download" style="height: 38px; width: auto;" /> </button> -->
                    <button type="button" class="btn btn-primary mt-1" data-toggle="modal" data-target="#exportModal">
                    Exportar datos 
                    </button>

                    <!-- <button class="btn navbar-item-list " @click="downloadVOSPapers"> Descargar vos test </button> -->
                </div>
                <div class="col-2 mt-2">
                    <div class="dropdown show">
                        <a class="btn  dropdown-toggle navbar-item-list" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            Ordenar Por
                        </a>
                        <div class="dropdown-menu " aria-labelledby="dropdownMenuLink">
                            <a class="dropdown-item" @click="orderType = 'none'" >Defecto</a>
                            <a class="dropdown-item" @click="orderType = 'citas_down'" >Referencias ↓</a>
                            <a class="dropdown-item" @click="orderType = 'citas_up'" >Referencias ↑</a>
                            <a class="dropdown-item" @click="orderType = 'year_down'">Año ↓</a>
                            <a class="dropdown-item" @click="orderType = 'year_up'">Año ↑</a>
                        </div>
                    </div>
                </div>
            </div>
            <ul class="list-group list-group-flush papers-list-layout">
                <li class="list-group-item mb-2 papers-list-item"  v-for="(paper, index) in filteredPapers_v2"  v-bind:key="index" v-if="!arrayIndexRemove.includes(index)">
                    <div class="row">
                        <div class="form-check col-auto ">
                            <input class="form-check-input" type="checkbox" :value="index" v-model="paperselected" >
                        </div>
                        <a  class="col-11" :href="paper['url']" target="_blank"><b><p class="limit-text">{{paper['title']}}</p></b></a>
                    </div>
                    <div class="row">
                        <div class="col-2">
                            <p>Citas: <b> {{paper['citationCount']}}</b></p>
                        </div>
                        <div class="col-4">
                            <p>Autores: <b> {{paper['authors']}}</b></p>
                        </div> 
                        <div class="col-1">
                            <p>Año: <b> {{paper['year']}}</b></p>
                        </div>
                        <div class="col-4">
                            <p>Revista: <b> {{paper['venue']}}</b></p>
                        </div>
                    </div>
                    <div class="row">
                        <p  class="limit-text" >{{paper['abstract']}}</p>
                    </div>
                </li>
            </ul> 
        </div>
        <div v-else class="papers-no-data">
            <label class="papers-label">Realizar una búsqueda para comenzar</label>
            <img :src="require('@/assets/images/faro_background.svg')" alt="logo DSP Tool" class="papers-no-data-img" />
        </div>

        <div class="modal" id="exportModal" tabindex="-1" role="dialog">
            <div class="modal-dialog modal-lg" role="document">
                <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Tipos de datos a exportar</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">

                    <div class="form-check">
                        <input class="form-check-input" type="radio" value="select" v-model="file_type" checked>
                        <label class="form-check-label" for="flexRadioDefault1">
                            Exportar papers seleccionados (.csv)
                            <span></span>
                        </label>
                    </div>
                    <div class="form-check mt-1">
                        <input class="form-check-input" type="radio" value="all_papers" v-model="file_type" >
                        <label class="form-check-label" for="flexRadioDefault2">
                            Exportar todos los papers para vosviewer (.json)
                            <br>
                            <span>Los papers exportados no contaran con todos los parametros</span>
                        </label>
                    </div>
                    <div class="form-check mt-1">
                        <input class="form-check-input" type="radio" value="papers_detail" v-model="file_type">
                        <label class="form-check-label" for="flexRadioDefault2">
                            Exportar papers con todos los parametros para vosviewer (.json)
                            <br>
                            <span>Solo es posible exportar un maximo de 80 papers</span>
                            <br>
                            <span>Nº de papers a exportar: </span>
                            <input type="number" class="number-input" v-model=n_resultados min="0" max="80">
                        </label>
                    </div>
                    <div class="form-check mt-1">
                        <input class="form-check-input" type="radio" value="OIDs" v-model="file_type" >
                        <label class="form-check-label" for="flexRadioDefault2">
                            Exportar todos los OIDs de los papers para vosviewer (.txt)
                            <br>
                        </label>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal"  @click="exportPapers()" >Exportar</button>
                </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import { stringArray,csvmaker } from "@/services/helper";
import { postData } from "@/services/api";

export default {
  name: 'papersList',
  data () {
    return {
        arrayIndexRemove:[],
        paperselected: [],
        selectAll: false,
        file_type: 'select',

    }
  },
  computed: {
    ...mapGetters({
        papers_raw: 'vtmar/papers_raw',
        tableFilter: 'vtmar/tableFilter',
        papers_raw_sort: 'vtmar/papers_raw_sort',
        query:'vtmar/query'
    }), 
    n_resultados: {
        set (value) { this.$store.dispatch('vtmar/change_formSearch', ['n_resultados',value]) },
        get () { return this.$store.state.vtmar.formSearch.n_resultados }
    },
    filteredPapers_v2() {
        this.arrayIndexRemove = []
        for (let index = 0; index < this.papers_raw_sort(this.orderType).length; index++) {
            if (this.tableFilter['title']){
                if (!this.papers_raw_sort(this.orderType)[index]['title'].toLowerCase().includes(this.tableFilter['title'].toLowerCase()))
                    this.arrayIndexRemove.push(index)
            }
            if (this.tableFilter['abstract']){
                if (!this.papers_raw_sort(this.orderType)[index]['abstract'].toLowerCase().includes(this.tableFilter['abstract'].toLowerCase()))
                this.arrayIndexRemove.push(index)
            }
            if (this.tableFilter['year'] && this.tableFilter['year'].length == 4){
                if (this.papers_raw_sort(this.orderType)[index]['year'] != this.tableFilter['year'])
                    this.arrayIndexRemove.push(index)
            }
            if (this.tableFilter['magazine']){
                if (!this.papers_raw_sort(this.orderType)[index]['venue'].toLowerCase().includes(this.tableFilter['magazine'].toLowerCase()))
                this.arrayIndexRemove.push(index)
            }
            if (this.tableFilter['author']){
                if (!this.papers_raw_sort(this.orderType)[index]['authors'].toLowerCase().includes(this.tableFilter['author'].toLowerCase()))
                    this.arrayIndexRemove.push(index)
            }
            
        }
        return this.papers_raw_sort(this.orderType)
    }
  },
  methods: {
    getStringAuthors(array,label){
       return stringArray(array,label)
    },
    select() {
		this.paperselected = []
		if (!this.selectAll) {
            for (let index = 0; index < this.papers_raw_sort(this.orderType).length; index++) {
                if (!this.arrayIndexRemove.includes(index))
                    this.paperselected.push(index);
            }
		}
	},
    exportPapers(){
        if(this.file_type == 'select'){
            this.downloadPapers()
        }else{
            this.downloadVOSPapers()
        }
    },
    downloadPapers(){
        var papersList = []
        var papers_raw_aux = [...this.papers_raw_sort(this.orderType)]
        for (let index = 0; index < this.papers_raw.length; index++) {
            if (this.paperselected.includes(index)){
                papersList.push(papers_raw_aux[index])
            }
        }
        var data = csvmaker(papersList)
        // Creating a Blob for having a csv file format
        const blob = new Blob(["\uFEFF"+data], { encoding:"UTF-8",type: 'text/csv;charset=UTF-8' });
        const url = window.URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.setAttribute('href', url)
        a.setAttribute('download', this.query + '_data.csv');
        a.click()
    
    },
    downloadVOSPapers(){
        var file_type = this.file_type //OIDs, all_papers, papers_detail 
        var n_papers = this.n_resultados
        postData('acquisition/download/',{file_type,n_papers}).then(res =>{
            if (res.status == 200){
                console.log(res.data)
                var data = res.data
                var type_data = 'txt'
                if (file_type != 'OIDs'){
                    data = JSON.stringify(data, null, 4)
                    type_data = 'json'
                }
                
                const blob = new Blob(["\uFEFF"+data], { encoding:"UTF-8",type: 'text/'+ type_data +';charset=UTF-8' });
                const url = window.URL.createObjectURL(blob)
                const a = document.createElement('a')
                a.setAttribute('href', url)
                a.setAttribute('download', this.query + '_' + file_type + '.' + type_data);
                a.click()

            }else{
                this.$noty.error("Repita la busqueda")
            }     
        })
        .catch(error => {
            this.$noty.error(str(error))
        })
    
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang= "scss" scoped>

.limit-text {
    overflow-y: hidden;
    overflow-x:hidden;
    text-overflow: ellipsis;
    max-height: 70px;      /* fallback */

}

.table-header{
    position:sticky;
    top: 0 ;
}
</style>