const state = () => ({
  status: 1, // 1.- Normal / 2.- Loading / 3.- Waiting for response / 4.- Error
  loading_papers: 0 // 0.- No data / 1.- Loading / 2.- data
})

const mutations = {
  set_status(state, data){
    state.status = data
  },
  set_loading_papers(state, data){
    state.loading_papers = data
  },
  
}

const actions = {
  change_status({ commit }, status) {
    commit("set_status", status)
  },
  change_loading_papers({ commit }, loading_papers) {
    commit("set_loading_papers", loading_papers)
  }
}

 const getters = {
    status: state => state.status,
    loading_papers: state => state.loading_papers
  }

  export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
  }