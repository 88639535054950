<template>
    <div class="panel-container">
      <div class="panel-block">
        <div class="form-group mb-0">
          <label class="panel-label-primary">Buscar</label>
          <input type="text" v-on:keyup.enter = getPapersSearch() v-model=query class="general-input form-control"  aria-describedby="Buscador" >
          <!-- <label class="number-label mt-2 ml-3">Nº resultados  </label> -->
          <!-- <input type="number" class="number-input" v-model=n_resultados min="0" max="300"> -->
        </div>
      </div>
      <div class="panel-separator"></div>
      <div class="panel-block">
        <div class="form-group">
          <label class="panel-label-primary">Flitrar</label>
        </div>
        <div class="form-group">
          <label class="panel-label-secondary">Título</label>
          <input type="text" class="general-input form-control"  v-model=title aria-describedby="Título" placeholder="Título" >
        </div>
        <div class="form-group">
          <label class="panel-label-secondary">Abstract</label>
          <input type="text" class="general-input form-control" v-model=abstract aria-describedby="Abstract" placeholder="Abstract" >
        </div>
        <div class="form-group row">
          <div class="col">
            <label class="panel-label-secondary">Citas</label>
            <input type="text" class="general-input form-control" v-model=cita aria-describedby="Citas" placeholder="Citas"  maxlength="6">
          </div>
          <div class="col">
            <label class="panel-label-secondary">Año</label>
            <input type="text" class="general-input form-control" v-model=year aria-describedby="Año" placeholder="Año"   maxlength="4">
          </div>
        </div>
        <div class="form-group">
          <label class="panel-label-secondary">Revista</label>
          <input type="text" class="general-input form-control" v-model=magazine aria-describedby="Revista" placeholder="Revista" >
        </div>
        <div class="form-group">
          <label class="panel-label-secondary">Autores</label>
          <input type="text" class="general-input form-control" v-model=author aria-describedby="Autores" placeholder="Autores" >
        </div>
      </div>
      <div class="panel-separator"></div>
      <div class="mb-0">
        <button class="button-calculate ml-4" @click="filterPapers()"> Fliter</button>
      </div>
      <div class="panel-block pl-4 pr-2 pt-0">
        <!-- <label class="panel-label-primary">Filtrar</label> -->
        <Plotly v-if="histo_fechas.length > 0" :data="dataFechas" :layout="layoutFechas" :scrollZoom="false" :displayModeBar="false" id="fechasPlotly"></Plotly>
      </div>
      <div class="panel-separator "></div>
      <div class="panel-block  panel-radius pt-2 pb-0">
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="radio" name="log_op_and" v-model="log_op"  value="AND" checked>
          <label class="form-check-label" >AND</label>
        </div>
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="radio" name="log_op_or"  v-model="log_op"  value="OR">
          <label class="form-check-label" >OR</label>
        </div>
      </div>
      <div class="panel-block pt-1 pl-4 pr-2">
        <Plotly  v-if="histo_citas.length > 0" :data="dataCitas" :layout="layoutCitas" :scrollZoom="false" :displayModeBar="false" id="citasPlotly"></Plotly>
      </div>
    </div>
</template>

<script>
import { Plotly } from 'vue-plotly';
import { mapGetters } from "vuex";
export default {
  name: 'panel',
  components: {
    Plotly
  },
  data () {
    return {
      dataFechas:  [
        {
          x: [],
          name: 'fechasPlotly',
          histnorm: "count", 
          marker: {
            color: "#ffffff", 
            line: {
              color:  "#c6c6c6", 
              width: 1
            }
          },  
          type: "histogram", 
          xbins: {
            end: '', 
            size: '', 
            start: ''
          }
        }
      ],
      dataCitas:  [
        {
          x: [], 
          name: 'citasPlotly',
          histnorm: "count",
          marker: {
            color: "#ffffff", 
            line: {
              color:  "#c6c6c6", 
              width: 1
            }
          },  
          type: "histogram", 
          xbins: {
            end: '', 
            size: '', 
            start: ''
          } 
        }
      ],
      layoutFechas: {
        title: {
          text: 'Distribución de los papers por año ',
          font: {
            color: '#fff'
          }
        }, 
        displayModeBar: false,
        plot_bgcolor: "#606060",
        paper_bgcolor: "#606060",
        margin: {
          l: 0,
          r: 10,
          b: 10,
          t: 40,
          pad: 4
        },
        xaxis: {
          title: "",
          color: "#fff",
          linecolor: '#fff',
          rangeslider: {},         
        }, 
        yaxis: {
          title: "",
          color: "#606060"
        }
      },
      layoutCitas: {
        title: {
          text: 'Distribución de los papers por número de citas',
          font: {
            color: '#fff'
          }
        },  
        displayModeBar: false,
        plot_bgcolor: "#606060",
        paper_bgcolor: "#606060",
        margin: {
          l: 0,
          r: 10,
          b: 10,
          t: 40,
          pad: 4
        },
        xaxis: {
          title: "",
          color: "#fff",
          linecolor: '#fff',
          rangeslider: {
          },          
        }, 
        yaxis: {
          title: "",
          color: "#606060"
        }
      }
    }
  },
  computed: {
    ...mapGetters({
        marks_fechas: 'vtmar/marks_fechas', 
        fechas_range: 'vtmar/fechas_range', 
        histo_fechas: 'vtmar/histo_fechas',
        marks_citas: 'vtmar/marks_citas',
        citas_range: 'vtmar/citas_range',
        histo_citas: 'vtmar/histo_citas',
    }), 
    query: {
        set (value) { this.$store.dispatch('vtmar/change_formSearch', ['query',value]) },
        get () { return this.$store.state.vtmar.formSearch.query }
    },
    n_resultados: {
        set (value) { this.$store.dispatch('vtmar/change_formSearch', ['n_resultados',value]) },
        get () { return this.$store.state.vtmar.formSearch.n_resultados }
    },
    title: {
        set (value) { this.$store.dispatch('vtmar/change_tableFilter', ['title',value]) },
        get () { return this.$store.state.vtmar.tableFilter.title }
    },
    abstract: {
        set (value) { this.$store.dispatch('vtmar/change_tableFilter', ['abstract',value]) },
        get () { return this.$store.state.vtmar.tableFilter.abstract }
    },
    cita: {
        set (value) { this.$store.dispatch('vtmar/change_tableFilter', ['cita',value]) },
        get () { return this.$store.state.vtmar.tableFilter.cita }
    },
    year: {
        set (value) { this.$store.dispatch('vtmar/change_tableFilter', ['year',value]) },
        get () { return this.$store.state.vtmar.tableFilter.year }
    },
    magazine: {
        set (value) { this.$store.dispatch('vtmar/change_tableFilter', ['magazine',value]) },
        get () { return this.$store.state.vtmar.tableFilter.magazine }
    },
    author: {
        set (value) { this.$store.dispatch('vtmar/change_tableFilter', ['author',value]) },
        get () { return this.$store.state.vtmar.tableFilter.author }
    },

    min_fecha: {
        set (value) { this.$store.dispatch('vtmar/change_formFilter', ['min_fecha',value]) },
        get () { return this.$store.state.vtmar.formFilter.min_fecha }
    },
    max_fecha: {
        set (value) { this.$store.dispatch('vtmar/change_formFilter', ['max_fecha',value]) },
        get () { return this.$store.state.vtmar.formFilter.max_fecha }
    },
    min_cita: {
        set (value) { this.$store.dispatch('vtmar/change_formFilter', ['min_cita',value]) },
        get () { return this.$store.state.vtmar.formFilter.min_cita }
    },
    max_cita: {
        set (value) { this.$store.dispatch('vtmar/change_formFilter', ['max_cita',value]) },
        get () { return this.$store.state.vtmar.formFilter.max_cita }
    },
    log_op: {
        set (value) { this.$store.dispatch('vtmar/change_formFilter', ['log_op',value]) },
        get () { return this.$store.state.vtmar.formFilter.log_op }
    }

  },
  methods: {
     getPapersSearch: async function () {
      var res = await this.$store.dispatch('vtmar/get_papers_search')
      if (res == 'ok'){
        this.dataFechas[0].x = this.histo_fechas
        this.dataFechas[0].xbins = {
            end: this.fechas_range[1], 
            size: 1, 
            start: this.fechas_range[0]
          }

        this.dataCitas[0].x = this.histo_citas
        this.dataCitas[0].xbins = {
            end: this.citas_range[1], 
            size: 200, 
            start: this.citas_range[0]
        }

      }
    },
    filterPapers: async function (){
      var rangeFechas = this.layoutFechas.xaxis.range
      var rangeCitas = this.layoutCitas.xaxis.range
      this.min_fecha = Math.round(rangeFechas[0])
      this.max_fecha = Math.round(rangeFechas[1])
      this.min_cita = Math.round(rangeCitas[0])
      this.max_cita = Math.round(rangeCitas[1])

    }
   }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang= "scss" scoped>

</style>